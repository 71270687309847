import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD50XRuZscyPtLwcnc7ssZQm49InOMg-Xk",
  authDomain: "cubanitos-c5a44.firebaseapp.com",
  databaseURL: "https://cubanitos-c5a44-default-rtdb.firebaseio.com",
  projectId: "cubanitos-c5a44",
  storageBucket: "cubanitos-c5a44.appspot.com",
  messagingSenderId: "351526445343",
  appId: "1:351526445343:web:4124bd792432351093502a",
  measurementId: "G-P3CF8TNC8G"
};

export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
export const db = getFirestore(app)