import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import { GrFormPrevious } from 'react-icons/gr'
import { BsWhatsapp } from 'react-icons/bs'
import { DataContext } from '../context/DataProvider'
import toast, { Toaster } from 'react-hot-toast'
import './menu.css'
import { FiShoppingCart } from 'react-icons/fi'

function Main() {

  const value = useContext(DataContext)
  const [cart, setCart] = value.cart
  let getNumberItemsCart = value.getNumberItemsCart
  const [docs, setDocs] = useState([])
  const [total, setTotal] = useState(0)
  
  useEffect(() => {
    read()
    setTimeout(() => getTotal(),500)   
  }, [value])

  async function drop (id) {
    for (let i = 0; i < 3; i++) {
      cart.forEach((doc, index) => {
        if(doc[0] === id) { 
          cart.splice(index,1)
        } else if(doc[6] === id) { 
          cart.splice(index,1)
        }  
      })
    }    
    setCart([...cart])
  }

  async function wtp () {  
    let number = +573232156800
    //user
    let name = document.form.name.value
    let address = document.form.address.value
    let phone = document.form.phone.value
    let note = document.form.note.value

    //menu
    if(name!=='' && address!=='' && phone!=='') {
      let text = "Hola Cubanitos, me gustaría generar el siguiente pedido: %0A"  
      text += `%0A_Pedido generado desde la web Cubanitos_%0A`
      text += `%0A*Cliente:* ${name}%0A`
      text += `*Dirección:* ${address}%0A`
      text += `*Contacto:* ${phone}`
      if(note!=='') {
        text += `%0A*Nota:* ${note}`
      }
      text += ` %0A%0A`
      cart.forEach((doc) => {
        let nombre = doc[1]
        //let info = doc[2]
        let cant = doc[5]
        text += `${nombre}: (cant: ${cant}).%0A`
      })
      window.open(`https://wa.me/${number}?text=${text}`, '_blank');
    } else {
      toast.error('Por favor complete sus datos')
      document.form.name.focus()
    }
  }

  function getTotal () {
    let totalAux = 0
    cart.forEach((doc) => {
      let precio = doc[4]
      let cant = doc[5]
      let subtotal = cant * precio
      totalAux = totalAux + subtotal
    })
    setTotal(Number((totalAux)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))  
  }

  function getSubTotal (id) {
    let subtotal = 0
    cart.forEach((doc) => {
      if(doc[6] === id) {
        let precio = doc[4]
        let cant = doc[5]
        subtotal += cant * precio
      }
    })
    return subtotal
  }

  //leer

  const read = () =>  {
      let data = []
      cart.forEach((doc) => {
        let subs = []
        let id = doc[0]
        let nombre = doc[1]
        let info = doc[2]
        let url = doc[3]
        let precio = doc[4]
        let cant = doc[5]
        let idSup = doc[6]
        let subtotal = (Number((precio*cant)+getSubTotal(id)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))

        subs = readSubs(id) 
        if(idSup == null) {
          data.push(
            <div className="col-12" key={`buy${id}`}>                               
              <div className="row p-3">
                <div className="col-11">
                  <h3 className="h5">{nombre}</h3> 
                </div>
                <div className="col-1 text-end">
                  <div className=' w-100'>                     
                    <div style={{cursor: 'pointer'}} className="h6" onClick={() => drop(`${id}`)}><AiOutlineClose/></div>  
                  </div>
                </div>
                <div className="col-5 col-lg-3">
                  <img src={url}  alt="..." className='w-100 rounded-4 my-2' loading="lazy"/> 
                </div>
                <div className="col-6">
                </div>
                <div className="col-9">
                  <span className='text-start small'>{info} </span>
                  <br/>
                  <span className='text-start small'>Precio por unidad {precio}, </span>
                  <span className='text-start small'>cant {cant}</span> 
                </div>
                <div className="col-3 text-end">
                  <span className='small'>${cant*precio}</span>    
                </div>
                {subs}
                <div className="col-12 text-end border-bottom border-secondary pb-2">
                  <h4 className="h5" id={`precio${id}`}>                      
                      {subtotal}
                  </h4>
                </div>
              </div>                          
            </div>        
          )
        }
      })
      setDocs(data)
  }

  const readSubs = (idSup) =>  {
    let data = []
    cart.forEach((doc) => {
      let id = doc[0]
      let nombre = doc[1]
      let info = doc[2]
      //let url = doc[3]
      let precio = doc[4]
      let cant = doc[5]
      let idSupAux = doc[6]
      let subtotal = (Number((precio)*cant))
      if(idSupAux === idSup) {
        data.push (
          <div className="col-12" key={`buy${id}`}>                               
            <div className="row border-top">
              <div className="col-9">
                <small className="fw-bold">{nombre}</small> 
                <br/>
                <span className='text-start small'>{info}</span>
                <br/>
                <span className='text-start small'>Precio por unidad {precio}, </span>
                <span className='text-start small'>cant {cant}</span>  
              </div>
              <div className="col-3 text-end">
                <span className='small'>${subtotal}</span>     
              </div>
            </div>                          
          </div>        
        )
      }
    })
    return data
  }

  //page 

  let page =  
    <>        
      <nav className="fixed-top bg-blur p-2" >
        <Link to='/menu/sandwiches' className='previous h2'><GrFormPrevious/></Link>
        <div className="text-center">
          <h1 id="page" className='h3 text-danger'>CARRITO</h1>
        </div>
        <div className="cart-container">
            <Link to='/cart' className='px-lg-2 cart m-0 p-0'><FiShoppingCart/></Link>
            <span className="position-absolute translate-middle badge rounded-pill bg-danger">
              {getNumberItemsCart()}
            </span>
          </div>
      </nav>   
      <div className="container px-lg-5 pb-5 pt-5 animate__animated animate__fadeIn">
      <div className='row mt-lg-5 d-flex justify-content-center'>
        <div className='col-12 col-lg-5 px-lg-3'>
          <div className="row mt-4 bg-white rounded border border-secondary">                
            {docs}
            <div className='text-black p-4'>                   
              <form name="form" className="form" id="form">
                <div className="row small px-1">
                <h1 className='h5 mb-4 text-dark text-center'>DATOS DE PEDIDO</h1>          
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label">*Nombre</label>
                      <input type="text" name="name" className="form-control rounded-4" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">*Dirección de pedido</label>
                      <input type="text" name="address" className="form-control rounded-4"/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">*Celular de contacto</label>
                      <input type="text" name="phone" className="form-control rounded-4"/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">¿Alguna nota adicional?</label>
                      <textarea className="form-control rounded-4" name="note" rows="2"></textarea>
                    </div>
                  </div>
                </div>
              </form>
              <div className=' w-100 text-center'>  
                <h1 className='h4 mb-3'>{total}</h1>            
                {/*<b className="btn btn-dark rounded-0 m-1 font disabled" onClick={() => pago()}><i className="me-1"><BsCreditCard/></i>Pago en línea</b>*/}
                <b className="btn btn-success  rounded-4 m-2" onClick={() => wtp()}><i className="me-1"><BsWhatsapp/></i>Pedir contraentrega</b>  
                <small className="text-center text-secondary d-block">*Válido para la ciudad de Pasto, precio sujeto a variaciones</small>             
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>  

    </>

  return ( 
    <>
    <div className="bg" />
    <Toaster
     position="top-center"
     reverseOrder={false}
     gutter={10}
     containerClassName=""
     containerStyle={{
     }}
     toastOptions={{
       // Define default options
       className: '',
       duration: 5000,
       style: {
         background: '#363636',
         color: '#fff',
         font: 'menu',
         fontSize: '13.5px',
         borderRadius: '0'
       },
   
       // Default options for specific types
       success: {
         duration: 3000,
         theme: {
           primary: 'green',
           secondary: 'black',
         },
       },
     }}
    />  
    {page}    
    </>
  )

}

export default Main