import React,{useState, useEffect, useContext} from 'react'
import { DataContext } from './../../context/DataProvider'
import './sections.css'
import { GrFormClose } from 'react-icons/gr'

const Section = () => {
  const value = useContext(DataContext)
  const [querySnapshot] = value.publicidad
  const [docs, setDocs] = useState([])
  const [img, setImg] = useState()
  
  useEffect(() => {
    read()
  }, [value])

  const read = async () =>  {
    let data = []
    querySnapshot.forEach((doc) => {
      data.push(
        <div key={doc.id} className="col-12 col-lg-6 mb-3 mb-lg-0">
          <img src={doc.data().url} className="w-100 p-0" alt="..." loading="lazy" onClick={() => openModalImg(doc.data().url)}/>           
        </div>  
      )
    })
    setDocs(data)
  }

  const closeModalImg = () =>  {
    document.getElementById("modalImg").style.display = 'none'
    document.body.style.overflow="auto"
  }

  const openModalImg = (imgAux) =>  {
    setImg(imgAux)
    document.getElementById("modalImg").style.display = 'flex'
    document.body.style.overflow="hidden"
  }

  return (     
    <div id="carouselPublicity">
      <div className="modal-img" id="modalImg">
        <div onClick={() => closeModalImg()} className='previous-right h2 invert'><GrFormClose/></div>
        <div>
          <img src={img} className="w-100 p-0" alt="..." loading="lazy"/>           
        </div>
      </div>
      <div className="px-3">
        <div className="row mt-5 pt-lg-5">
          <div className="col-12">      
            <h1 className='h2 text-black text-center mb-5'><div className='signo'></div>Noticias Cubanitos</h1>
          </div>    
          {docs}                 
        </div>
      </div>
   </div>
  )
}

export default Section