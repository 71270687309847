import React,{useState, useEffect, useContext} from 'react'
import { DataContext } from './../../context/DataProvider'
import './sections.css'
import { Link } from 'react-router-dom'

const Section = () => {
  const value = useContext(DataContext)
  const [querySnapshot] = value.publicaciones

  const [docs, setDocs] = useState([])
  
  useEffect(() => {
    read()
  }, [value])

  const read = async () =>  {
      let data = []
      querySnapshot.forEach((doc) => {
        data.push(
          <div key={doc.id} className="col-6 col-lg-3 mb-3 mb-lg-0">
            <Link to={doc.data().info} className="text-decoration-none">
              <img src={doc.data().url} className="w-100 shadow rounded" alt="..." loading="lazy"/>         
              <div className="container-text-news">
                <b className="text-news">{doc.data().nombre}</b>
              </div>
            </Link>
          </div>  
        )
      })
      setDocs(data)
  }

  return (
    <section className='news' id='news'>
      <div className="px-3">
        <div className="row mt-5 pt-lg-5">
          <div className="col-12">      
            <h1 className='h2 text-black text-center'><div className='signo'></div>Especial para ti</h1>
            <h3 className='h3 text-dark text-center mb-5'><div className='signo'></div>Arma tus combos</h3>
          </div>    
          {docs}                 
        </div>
      </div>
    </section>
  )
}

export default Section