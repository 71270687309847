import { useContext, createContext, useEffect, useState} from "react"
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "firebase/auth"
import { DataContext } from './DataProvider'
import { app } from "./../Firebase"

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {

    const auth = getAuth(app)
    const [ user, setUser ] = useState({})
    const value = useContext(DataContext)
    const [querySnapshot] = value.admins

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
        .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result);
        //const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user

        //Validation
        let validation = false
        querySnapshot.forEach((doc) => {
            if (user.email === doc.data().nombre) {
                validation = true
            }
        })
        if(!validation) {
            googleSignOut()
        }
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log({errorCode, errorMessage, email, credential})
        })
    }

    const googleSignOut = () => {
        signOut(auth)
    }    

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
            setUser(currentUser)
        })
        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <AuthContext.Provider value={{ googleSignIn, googleSignOut, user }}>
            {children}
        </AuthContext.Provider>
    )
}

export const Auth = () => {
    return useContext(AuthContext)
}