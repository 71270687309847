import React, { useState, useEffect, useContext} from 'react'
import './sections.css'
import logo from '../../assets/img/logoheader.png'
import { DataContext } from './../../context/DataProvider'
import { GrFormNext,GrFormPrevious } from 'react-icons/gr'
import { Link } from 'react-router-dom'

const Section = () => {
  const value = useContext(DataContext)
  const [querySnapshot] = value.portada
  
  const [docs, setDocs] = useState([])
  const [docsMovil, setDocsMovil] = useState([])
  
  useEffect(() => {
    read()
  }, [value])

  const read = async () =>  { 
      let data = []
      let dataMovil = []
      let i = 0
      let j = 0
      querySnapshot.forEach((doc) => {
        let activeAuxi = 'active'
        let activeAuxj = 'active'
        if(i>0) activeAuxi = ''
        if(j>0) activeAuxj = ''
        if (doc.data().nombre === 'movil'){
          dataMovil.push(
            <div key={doc.id} className={`carousel-item ${activeAuxj}`}>    
              <img src={doc.data().url} className="images-portada" loading="lazy" alt="..."/>                          
            </div>
          )
          j++
        }
         else {
          data.push(
            <div key={doc.id} className={`carousel-item ${activeAuxi}`}>    
              <img src={doc.data().url} className="images-portada" loading="lazy" alt="..."/>                          
            </div>
          )
          i++;
        }

      })
      setDocs(data)
      setDocsMovil(dataMovil)
  }

  return (
    <section className='header animate__animated animate__fadeIn ' id='header'>       
      <div id="carouselExampleFade" className="carousel slide carousel-fade d-none d-lg-block" data-bs-ride="carousel"> 
        <div className="carousel-inner">
          {docs}
          <div className="stamp">
            <img className='logo-header' src={logo} loading="lazy" alt=""/>  
            <div className="text-header">
              <h1 className='h1 ms-lg-5 ps-lg-5 ps-3 text-light '>EL MEJOR <br/>RESTAURANTE  </h1>
            </div>       
            <div className='btn-menu-header'>
              <Link to={`/menu/sandwiches`} className='btn-red'>HAZ TU PEDIDO</Link>
            </div>    
          </div> 
        </div>
        <button className="carousel-control-prev"  data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <div className="text-danger h3"><GrFormPrevious/></div>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <div className="text-danger h3"><GrFormNext/></div>
          <span className="visually-hidden">Next</span>
        </button>
      </div> 
      <div id="carousel2" className="carousel slide carousel-fade d-block d-lg-none" data-bs-ride="carousel"> 
        <div className="carousel-inner">
          {docsMovil}
          <div className="stamp">
            <img className='logo-header' src={logo} loading="lazy" alt=""/>  
            <div className="text-header">
              <h1 className='h1 ms-lg-5 ps-lg-5 ps-3 text-light '>EL MEJOR <br/>RESTAURANTE </h1>
            </div>       
            <div className='btn-menu-header'>
              <Link to={`/menu/sandwiches`} className='btn-red'>HAZ TU PEDIDO</Link>
            </div>    
          </div> 
        </div>
        <button className="carousel-control-prev"  data-bs-target="#carousel2" data-bs-slide="prev">
          <div className="text-danger h3"><GrFormPrevious/></div>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carousel2" data-bs-slide="next">
        <div className="text-danger h3"><GrFormNext/></div>
          <span className="visually-hidden">Next</span>
        </button>
      </div>   
    </section>
  )
}

export default Section