import React, { useState, useEffect } from 'react'
import AdminNav from './../components/Nav/AdminNav'
import AdminFooter from './../components/Footer/AdminFooter'
import { MdCloudUpload } from 'react-icons/md'
import { ImCheckmark } from 'react-icons/im'
import { AiOutlineDelete } from 'react-icons/ai'
import { FaImage } from 'react-icons/fa'
import { storage, db } from './../Firebase'
import { ref, uploadBytesResumable, getDownloadURL, getStorage, deleteObject  } from "firebase/storage"
import { collection, doc, updateDoc, addDoc, getDocs, query, deleteDoc, orderBy } from "firebase/firestore"


function Main() {

  const [active, setActive] = useState('#page')
  const [subActive, setSubActive] = useState('publicaciones')
  const [url, setUrl] = useState('')
  const [collectionRef, setCollectionRef] = useState('/pagina/none/publicaciones')
  const [filename, setFilename] = useState('')
  const [docs, setDocs] = useState([])
  const [lectura, setLectura] = useState(1)

  useEffect(() => {
    read('/pagina/none/publicaciones', 'publicaciones')
  }, [])
  
  const menu = (url, dir, current) => {
    setCollectionRef(dir)
    setActive(url)
    submenu(current, dir)
  }

  function submenu (current, dir) {
    setCollectionRef(dir)
    setSubActive(current)
    read(dir, current)
    let form = document.getElementById('form')
    form.style.display = 'block'
  }

  //cargar

  const upload = async (e) => {
    const file = e.target.files[0]
    if(file.size > 500000) alert("La imagen es muy grande, se recomienda menos de 500KB")
    else {
      console.log(file.size)
      const storageRef = ref(storage)
      const imagesRef = ref(storageRef, collectionRef)
      const spaceRef = ref(imagesRef, file.name )
      const uploadTask = await uploadBytesResumable(spaceRef, file) //upload
      getDownloadURL(spaceRef).then((url) => { 
        setUrl(url) 
        setFilename(file.name)
      })   
      console.log(uploadTask)
      document.getElementById("submit").disabled = false
    }
  }

  //crear

  const add = async (e) => {
    e.preventDefault()
    document.getElementById("submit").disabled = true //disabled
    try {
        const docRef = await addDoc(collection(db, collectionRef), {
          info: e.target.info.value,
          nombre: e.target.nombre.value,        
          precio: e.target.precio.value,        
          filename: filename,        
          url: url
        })
        console.log("Document written with ID: ", docRef.id);   
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    setUrl('')
    read(collectionRef)
  }

  //leer

  const read = async (dir, current) =>  {
    setLectura(lectura+1)
    try {
      const q = query(collection(db, dir), orderBy("nombre"))
      const querySnapshot = await getDocs(q)
      let data = []
      querySnapshot.forEach((doc) => {
        data.push(
          <div key={doc.id} className="col-4 docs">
            <a href={`#item${doc.id}`} className="text-black mx-1 text-decoration-none d-inline-block" data-bs-toggle="offcanvas">
              <img src={doc.data().url} className="card-img-top" alt="..."/>   
              <div className="icon-delete fw-bold" onClick={() => borrar(dir, doc.id, doc.data().filename)}><AiOutlineDelete/></div> 
              <h6 className="p-1 text-center fw-bold">{doc.data().nombre}</h6>             
            </a>    
            <div className="offcanvas offcanvas-end p-3 bg-blur" tabIndex="-1" id={`item${doc.id}`} data-bs-backdrop="false">
              <form name="formdata" id='formdata' className='m-0 mb-2'>       
                <div className="offcanvas-header">
                  <h3 className="offcanvas-title text-capitalize fw-bold">{current}</h3>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">  
                  <img src={doc.data().url} className="img-fluid" alt="..."/> 
                  <div className="input-group my-3">
                    <span className="input-group-text" id={`loadingNombre${doc.id}`}  style={{display: 'none'}} onClick={() => updateNombre(dir, doc.id)}>
                      <div className="spinner-grow spinner-grow-sm text-success" role="status"></div>
                    </span>
                    <span className="input-group-text" id={`saveNombre${doc.id}`}>
                      <i id='icon-success' className="text-success"><ImCheckmark/></i>
                    </span>
                    <input className="form-control" type="text" name="nombre" id={`nombre${doc.id}`} onChange={() => changeNombre(doc.id)} placeholder="Nombre" defaultValue={doc.data().nombre}/>    
                  </div>   
                  <div className="input-group mb-3">
                    <span className="input-group-text" id={`loadingPrecio${doc.id}`}  style={{display: 'none'}} onClick={() => updatePrecio(dir, doc.id)}>
                      <div className="spinner-grow spinner-grow-sm text-success" role="status"></div>
                    </span>
                    <span className="input-group-text" id={`savePrecio${doc.id}`}>
                      <i id='icon-success' className="text-success"><ImCheckmark/></i>
                    </span>
                    <input className="form-control" type="number" name='precio' id={`precio${doc.id}`} onChange={() => changePrecio(doc.id)} placeholder="Número" defaultValue={doc.data().precio} />
                  </div>   
                  <div className="input-group mb-3">
                    <span className="input-group-text" id={`loadingInfo${doc.id}`}  style={{display: 'none'}} onClick={() => updateInfo(dir, doc.id)}>
                      <div className="spinner-grow spinner-grow-sm text-success" role="status"></div>
                    </span>
                    <span className="input-group-text" id={`saveInfo${doc.id}`}>
                      <i id='icon-success' className="text-success"><ImCheckmark/></i>
                    </span>
                    <textarea className="form-control" name="info" rows="9" cols="31" id={`info${doc.id}`} onChange={() => changeInfo(doc.id)} placeholder="Detalle" defaultValue={doc.data().info}></textarea>  
                  </div>   
                </div>
              </form>
            </div>          
          </div>
        ) //console.log(doc.id, " => ", doc.data())
      })
      setDocs(data)
    } catch (e) {
      setDocs(<h3 className="text-danger">{e.message}</h3>)
      console.error(e);
    }
    console.log('Operaciones de lectura:', lectura)
  }

  //actualizar

  const changeInfo = async (documento) => {
    document.getElementById('saveInfo'+documento).style.display='none'
    document.getElementById('loadingInfo'+documento).style.display='block'
  }

  const updateInfo = async (dir, documento) => {
    const info = document.getElementById('info'+documento)
    const docRef = doc(db, dir.toString(), documento.toString())
    await updateDoc(docRef, {
      info: info.value
    })
    .then(() => { 
      document.getElementById('saveInfo'+documento).style.display='block'
      document.getElementById('loadingInfo'+documento).style.display='none'
    })  
    read(dir)
  }

  const changeNombre = async (documento) => {
    document.getElementById('saveNombre'+documento).style.display='none'
    document.getElementById('loadingNombre'+documento).style.display='block'
  }

  const updateNombre = async (dir, documento) => {
    const nombre = document.getElementById('nombre'+documento)
    const docRef = doc(db, dir.toString(), documento.toString())
    await updateDoc(docRef, {
      nombre: nombre.value
    })
    .then(() => { 
      document.getElementById('saveNombre'+documento).style.display='block'
      document.getElementById('loadingNombre'+documento).style.display='none'
    })  
    read(dir)
  }

  const changePrecio = async (documento) => {
    document.getElementById('savePrecio'+documento).style.display='none'
    document.getElementById('loadingPrecio'+documento).style.display='block'
  }

  const updatePrecio = async (dir, documento) => {
    const precio = document.getElementById('precio'+documento).value.toString()
    const docRef = doc(db, dir.toString(), documento.toString())
    await updateDoc(docRef, {
      precio: precio
    })
    .then(() => { 
      document.getElementById('savePrecio'+documento).style.display='block'
      document.getElementById('loadingPrecio'+documento).style.display='none'
    })  
    read(dir)
  }

  //borrar

  const borrar = async (dir, documento, filename) => {
    if(window.confirm("¿Está seguro de eliminar este registro?")) {
      const docRef = doc(db, dir.toString(), documento.toString())
      await deleteDoc(docRef) 
      const storage = getStorage()    
      const desertRef = ref(storage, dir+'/'+filename);
      deleteObject(desertRef).then(() => {
        read(dir)
      })
    }     
  }  


  //page 

  let page =  
    <>
      <AdminNav/>
      <div className="container pb-5 mb-5">
        <div className="row">
          <div className="col-3 d-none d-lg-inline">
            <div className="options lead">
              <div id="list-example" role="tablist" className="list-group list-group-flush">              
                <a className={`list-group-item list-group-item-action display-5 title ${active === '#admins' ? 'active' : ''}`} href="#admins" onClick={() => menu('#admins','/admins/none/admins', 'contacto')}>Admins</a>
                <a className={`list-group-item list-group-item-action display-5 title ${active === '#page' ? 'active' : ''}`} href="#page" onClick={() => menu('#page','/pagina/none/contacto', 'contacto')}>Mi página</a>
                <a className={`list-group-item list-group-item-action display-5 title ${active === '#products' ? 'active' : ''}`} href="#products" onClick={() => menu('#products','/productos/none/sandwiches', 'sandwiches')}>Productos</a>
              </div>          
            </div>      
          </div>
          <div className="col-9">
            <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">             
              <h1 id="page" className='pt-6 mb-4 display-5 title'>Opciones</h1>
              <div className='row section'>
                <div className='col-3'>
                  <ul className="list-group list-group-flush" id='submenu'>
                    {active === '#admins'
                      &&  
                      <>
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'contacto' ? 'active' : ''}`} onClick={() => submenu('admins','/admins/none/admins')}>Administradores</li>
                      </>
                    }
                    {active === '#page'
                      &&  
                      <>
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'contacto' ? 'active' : ''}`} onClick={() => submenu('contacto','/pagina/none/contacto')}>Contacto</li>
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'portada' ? 'active' : ''}`} onClick={() => submenu('portada','/pagina/none/portada')}>Portada</li>
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'publicaciones' ? 'active' : ''}`} onClick={() => submenu('publicaciones','/pagina/none/publicaciones')}>Publicaciones</li>                                
                      </>
                    }
                    {active === '#products'
                      &&  
                      <>                      
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'sandwiches' ? 'active' : ''}`} onClick={() => submenu('sandwiches','/productos/none/sandwiches')}>Sandwinches</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'hamburguesas' ? 'active' : ''}`} onClick={() => submenu('hamburguesas','/productos/none/hamburguesas')}>Hamburguesas</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'perros' ? 'active' : ''}`} onClick={() => submenu('perros','/productos/none/perros')}>Perros calientes</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'otros' ? 'active' : ''}`} onClick={() => submenu('otros','/productos/none/otros')}>Otros</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'bebidas' ? 'active' : ''}`} onClick={() => submenu('bebidas','/productos/none/bebidas')}>Bebidas</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'combos' ? 'active' : ''}`} onClick={() => submenu('combos','/productos/none/combos')}>Combos</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'publicidad' ? 'active' : ''}`} onClick={() => submenu('publicidad','/productos/none/publicidad')}>Publicidad</li>              
                        <li className={`list-group-item user-select-none fw-bold ${subActive === 'adicionales' ? 'active' : ''}`} onClick={() => submenu('adicionales','/productos/none/adicionales')}>Adicionales</li>              
                      </>              
                    }
                  </ul>
                </div>
                <div className='col-9'>
                  <div className="row">
                  <form name="form" id='form' className='m-0 mb-2' onSubmit={add} style={{display: 'none'}}>   
                    <input type="file" id="file" onChange={upload} className="d-none"/>
                    <img src={url} width="100" className="d-block" alt=""/>
                    <label htmlFor="file" className="bg-dark text-white small p-1 px-3 my-3"><i className="px-1"><FaImage/></i> Seleccionar imagen</label>
                    <input type="hidden" name='info' />
                    <input type="hidden" name='nombre' />
                    <input type="hidden" name='precio' />
                    <button type="submit" className="bg-warning small p-1 px-3 my-3 border-0 fw-bold" disabled id="submit"><i className=""><MdCloudUpload/></i> Cargar</button>
                  </form>
                  {docs}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
      <AdminFooter/>
    </>

  return ( 
    <>
     <div className="container  d-block d-lg-none">
        <h1 className="pt-5 px-3 text-secondary">
          Hola, lo sentimos, para un correcto funcionamiento el Administrador solo se encuentra habilitado en pantallas grandes :)
         </h1>
      </div>
      <div className="d-none d-lg-block">{page}   </div> 
    </>
  )

}

export default Main