import React from 'react'
import Nav from './../components/Nav/Nav'
import Footer from './../components/Footer/Footer'
import Header from './../components/Sections/Header'
import News from './../components/Sections/News'
import Publicity from './../components/Sections/Publicidad'
import Map from './../components/Sections/Map'

const Main = () => {
      
  let page =  
  <>
    <Nav/>
    <Header/>
    <News/>
    <Publicity/>
    <Map/>
    <Footer/>
  </>
    return (
      <>
      <div className="bg" />
      {page}       
      </>
    )

}

export default Main