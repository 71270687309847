import React, { Component } from 'react'
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '55vh'
};
const center = {
  lat: 1.2182420627556345,
  lng: -77.28898760253362
}

const position = {
  lat: 1.2182420627556345,
  lng: -77.28898760253362
}

class MyComponent extends Component {
  render() {
    
    return (
      <div className="pt-lg-5 mt-5">
        <h1 className="text-black text-title text-center h2">VISÍTANOS</h1>
        <h1 className="text-dark text-center text-second h4 mb-5">PANAMERICANA, PASTO - NARIÑO</h1>
        <div>
          <LoadScript
            googleMapsApiKey={'AIzaSyCm1DCz6lwRXaqvDaNUIaKKbLYh8IAvN1Y'}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}              
            >
              <Marker
                position={position}
              />
              {/*<MdLocationOn className="pin"/>*/}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
     
    )
  }
}

export default React.memo(MyComponent)