import React, {useEffect, useState, useContext} from 'react'
import { DataContext } from './../../context/DataProvider'
import logocubanos from '../../assets/img/logoc.png'
import logo from '../../assets/img/logodark.svg'

const Footer = () => {
  const value = useContext(DataContext)
  const [querySnapshot] = value.contacto

  const now = new Date()
  
  const [docs, setDocs] = useState([])
  
  useEffect(() => {
    read()
  }, [])

  const read = async () =>  {
      let data = []
      querySnapshot.forEach((doc) => {
        data.push(
          <div  key={doc.id} className="d-inline">
            <a target='_blank' href={doc.data().info} className='text-decoration-none' rel="noreferrer">
              <img src={doc.data().url}  alt="..." className='redes-icon' loading="lazy" title={doc.data().nombre}/>    
            </a>                                    
          </div>    
        )
      })
      setDocs(data)
  }
  
  return (
    <footer className="bg-blur" id="contact">
      <div className="container-fluid">
        <div className="row p-4  pt-5">
          <div className="col-12 text-center">
            <img src={logocubanos} className="mb-1" alt="" width="250" style={{filter: 'grayscale(100%)  brightness(10%)'}} />
            <br/>
            <span className="small" > ©{now.getFullYear()} Cubanitos mi favorito. Todos los derechos reservados.</span>
            <br/>
            {docs} 
          </div>
          <div className="col-12 text-end">
            <a href="https://uappex.com/" className="font text-decoration-none text-dark">Created by</a>
            <br/>
            <a href="https://uappex.com/"  target='_blank' rel="noreferrer"><img src={logo} alt="" className="ms-2" width="100" /></a>
          </div>
        </div>
      </div>
     
     
    </footer>
  )
}

export default Footer