import { createContext, useEffect, useState} from "react"
import { collection, getDocs, orderBy, query } from "firebase/firestore"
import { db } from "../Firebase"
import toast from 'react-hot-toast'

export const DataContext = createContext()

export const DataProvider = (props) => {
    const [admins, setAdmins] = useState([])
    const [contacto, setContacto] = useState([])
    const [portada, setPortada] = useState([])
    const [publicaciones, setPublicaciones] = useState([])
    const [sandwiches, setSandwiches] = useState([])
    const [hamburguesas, setHamburguesas] = useState([])
    const [perros, setPerros] = useState([])
    const [otros, setOtros] = useState([])
    const [bebidas, setBebidas] = useState([])
    const [combos, setCombos] = useState([])
    const [publicidad, setPublicidad] = useState([])
    const [adicionales, setAdicionales] = useState([])
    const [cart, setCart] = useState([])
    let subs = []

    useEffect(() => {
      
        const dataCart = JSON.parse(localStorage.getItem('dataCart'))
        if(dataCart) {
          if(Object.keys(dataCart).length > 0) {
            setCart(dataCart) 
          }
        }
        read('/admins/none/admins', 'admins')
        read('/pagina/none/contacto', 'contacto')
        read('/pagina/none/portada', 'portada')
        read('/pagina/none/publicaciones', 'publicaciones')
        read('/productos/none/sandwiches', 'sandwiches')
        read('/productos/none/hamburguesas', 'hamburguesas')
        read('/productos/none/perros', 'perros')
        read('/productos/none/otros', 'otros')
        read('/productos/none/bebidas', 'bebidas')
        read('/productos/none/combos', 'combos')
        read('/productos/none/publicidad', 'publicidad')
        read('/productos/none/adicionales', 'adicionales')
    }, [])

    const addCart = async (id, nombre, info, url, precio, cant, idSup) =>  {
      const aux = []
      let check = true;
      cart.forEach((document) =>{
        if(document[0] === id && (document[6] === null)) check = false;
      })
      if(check) {
        aux.push(id, nombre, info, url, precio, cant, idSup)
        setCart(cart => [...cart, aux])
        toast.success('Producto añadido')
      } else {
        toast.error('El producto ya se encuentra en mi pedido')
      }      
    }

    const getNumberItemsCart = () =>  {
      let i = 0;
      cart.forEach((item) =>{
        if(item[6] === null) i++
      })   
      return i
    }

    useEffect(() => {
      localStorage.setItem('dataCart', JSON.stringify(cart))
    }, [cart])


    const read = async (dir, current) =>  {
        try {
          const q = query(collection(db, dir), orderBy("precio"))
          const querySnapshot = await getDocs(q)

          if(current === 'contacto') setContacto(querySnapshot)
          else if(current === 'admins') setAdmins(querySnapshot)
          else if(current === 'portada') setPortada(querySnapshot)
          else if(current === 'publicaciones') setPublicaciones(querySnapshot)
          else if(current === 'sandwiches') setSandwiches(querySnapshot)
          else if(current === 'hamburguesas') setHamburguesas(querySnapshot)
          else if(current === 'perros') setPerros(querySnapshot)
          else if(current === 'otros') setOtros(querySnapshot)
          else if(current === 'bebidas') setBebidas(querySnapshot)
          else if(current === 'combos') setCombos(querySnapshot)
          else if(current === 'publicidad') setPublicidad(querySnapshot)
          else if(current === 'adicionales') setAdicionales(querySnapshot)         
        } catch (e) {
          console.error(e);
        }
        console.log('Operación de lectura')
      }

      const value = {
        admins: [admins], 
        contacto: [contacto], 
        portada: [portada], 
        publicaciones: [publicaciones], 
        sandwiches: [sandwiches], 
        hamburguesas: [hamburguesas], 
        perros: [perros], 
        otros: [otros], 
        bebidas: [bebidas], 
        combos: [combos], 
        publicidad: [publicidad], 
        adicionales: [adicionales],
        cart: [cart, setCart],
        addCart: addCart,
        getNumberItemsCart: getNumberItemsCart
      }
    return(
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    )
}