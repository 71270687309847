import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthContextProvider } from './context/AuthContext'
import { DataProvider } from './context/DataProvider'
import Main from './views/Main'
import AdminMain from './views/AdminMain'
import Login from './views/Login'
import Menu from './views/Menu'
import Cart from './views/Cart'

export default function App() {
    return (
        <DataProvider>
        <AuthContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Main />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/account' element={<AdminMain />} />
                    <Route path='/menu/:option' element={<Menu />}/>
                    <Route path='/cart' element={<Cart />} />
                    <Route path='/failure' element={<Main />} />
                    <Route path='/pending' element={<Main />} />
                </Routes>
            </BrowserRouter>
        </AuthContextProvider>
        </DataProvider>
    )
}
