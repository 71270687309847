import React, { useEffect} from 'react'
import {MdOutlineAdminPanelSettings} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Auth } from "../context/AuthContext"

const Login = () => {
  const {googleSignIn, user} = Auth()
  const navigate = useNavigate()  

  const handleSignIn = async () => {
    try {
      await googleSignIn()
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if (user !== null) {
      navigate('/account')
    }
  }) 
  
  return (
    <div className="login">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">               
            <h1 className="h3 mb-3">Bienvenido Administrador</h1>
            <p className="fw-bold">Por favor verifique su identidad e inicie sesión</p>
            <button className="btn btn-dark py-1 shadow rounded-0" onClick={handleSignIn}><MdOutlineAdminPanelSettings/> Verificar e ingresar</button>             
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login