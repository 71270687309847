import React, {useEffect} from 'react'
import { Auth } from "../../context/AuthContext"
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/img/logo.png'
import { GrLogout } from 'react-icons/gr'
import { MdAdminPanelSettings } from 'react-icons/md'
import './nav.css'

const Nav = () => {

  const {googleSignOut, user} = Auth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user === null) {
      navigate('/login')
    }
  })

  const handleSignOut = async () => {
    try {
      await googleSignOut()
    } catch (error) {
      
    }
  }
 
  return (
      <nav className="navbar navbar-admin navbar-expand-lg bg-blur pb-4  fixed-top">
      <div className="container">
        <a className="navbar-brand" href="#"> <img src={logo} alt="" className='logoadmin'/></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse nav justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
            <div className="dropdown">
              <a className="nav-link fw-bold text-black pb-1" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="position-absolute top-100 m-0 pt-0 start-50 translate-middle small text-success rounded-0">
                  <i className="h6"><MdAdminPanelSettings/></i> Administrador
                </span>  
                {user?.displayName}
                <img src={user?.photoURL} className='imguser' alt='' referrerPolicy = "no-referrer"/>          
              </a>
              <ul className="dropdown-menu rounded-0" aria-labelledby="dropdownMenuLink">
                <li><a className="dropdown-item user-select-none" onClick={handleSignOut}><span className='h5 px-2'><GrLogout/></span>Cerrar sesión</a></li>
              </ul>
            </div>
            </li>
          </ul>
        </div>
      </div>
    </nav> 
  )
}

export default Nav
