import React from 'react'

import { AiFillGithub } from 'react-icons/ai'

const Footer = () => {

 
  return (
      <nav className="navbar navbar-expand-lg bg-blur  fixed-bottom">
      <div className="container py-4">
        <div className="navbar-collapse nav justify-content-end">
          <a className="text-decoration-none text-black small pe-none"><b><AiFillGithub/> GitHub</b> Creado por UAPPEX</a>
        </div>
      </div>
    </nav> 
  )
}

export default Footer
