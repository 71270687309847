import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineMenu } from 'react-icons/ai'
import './nav.css'


const Nav = () => {
  const [active, setActive] = useState('#header')


  useEffect(() => {
    let element = document.getElementById('nav')
   window.addEventListener('scroll', function()  {
        if(element !== null) {
          if(window.scrollY> 10) {
            element.style.background = 'rgba(0,0,0,0.35) '
          } else {
            element.style.background = 'transparent'
          }
        }
    })
  })
 
  return (
    <nav className="navbar navbar-client navbar-expand-lg fixed-top animate__animated animate__fadeIn" id='nav'>
    <div className="container-fluid px-5">
      <div className="navbar-toggler text-light" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <AiOutlineMenu/>
      </div>
      <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">        
        <a href="#header" onClick={() => setActive('#header')} className={active === '#header' ? 'active text-nav' : 'text-nav'} rel="noreferrer">INICIO</a>
        <Link to={`/menu/sandwiches`} className='text-nav'>DOMICILIOS</Link>
        <a href="#contact" onClick={() => setActive('#contact')} className={active === '#contact' ? 'active text-nav' : 'text-nav'} rel="noreferrer">CONTACTO</a>
      </div>
    </div>
  </nav>     
  )

}

export default Nav
