import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { GrFormSubtract, GrFormAdd, GrFormPrevious, GrFormClose} from 'react-icons/gr'
import { PiBeerBottleDuotone, PiBowlFoodDuotone } from 'react-icons/pi'
import { FiShoppingCart } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'
import { DataContext } from './../context/DataProvider'
import { Toaster } from 'react-hot-toast'
import ReactDOM from 'react-dom/client'
import './menu.css'

function Menu() {  
  const value = useContext(DataContext)
  const navigate = useNavigate()
  const { option } = useParams()
  const [querySnapshot] = value.cart
  const addCart = value.addCart
  let getNumberItemsCart = value.getNumberItemsCart
  const [docs, setDocs] = useState([])
  const [extra, setExtra] = useState([])
  const [product, setProduct] = useState([])
  let subs = []


  useEffect(() => {
    read(value.sandwiches)
    if(option === 'hamburguesas') read(value.hamburguesas)
    if(option === 'perros') read(value.perros)
    if(option === 'otros') read(value.otros)
    if(option === 'bebidas') read(value.bebidas)
    if(option === 'publicidad') read(value.publicidad)
  }, [navigate, value])  


  function navigateScroll (url) {
    window.scrollTo(0, 0)
    navigate(url)
  }

  function add (id, precio) {
    let cant = Number(document.getElementById((`cant${id}`)).value)
    document.getElementById((`cant${id}`)).value = Number(cant +1)
    setPrecio(id,precio)
  }

  function sub (id, precio) {
    let cant = Number(document.getElementById((`cant${id}`)).value)
    if(cant > 1) document.getElementById((`cant${id}`)).value = Number(cant -1)
    else document.getElementById((`cant${id}`)).value = Number(1)
    setPrecio(id,precio)
  }

  function setPrecio (id, precio) {
    let cant = document.getElementById((`cant${id}`)).value
    let extraBebidas = 0
    let extrasAdicionales = 0
    if(document.getElementById((`precioExtrasbebidas${id}`)) != null) extraBebidas = Number(document.getElementById((`precioExtrasbebidas${id}`)).value)
    if(document.getElementById((`precioExtrasadicionales${id}`)) != null) extrasAdicionales = Number(document.getElementById((`precioExtrasadicionales${id}`)).value)
    if(cant > 0) {
      let element = document.getElementById((`precio${id}`))
      element.innerHTML=(Number((cant*precio + (extraBebidas+extrasAdicionales))).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))
    }
  }

  function addCartAux (doc) {
    let cant = document.getElementById((`cant${doc.id}`)).value
    let id = doc.id
    let nombre = doc.data().nombre
    let info = doc.data().info
    let url = doc.data().url
    let precio = doc.data().precio
    addCart(id, nombre, info, url, precio, cant, null)
    subs.forEach((item) => {
      if(item[6] === id) { 
        addCart(item[0], item[1], item[2], item[3], item[4], item[5], item[6])
      } 
    })
    closeModalProduct()
  }

  //leer

  const readExtra = ([val], idSup, type, precioSup ) =>  {
    let data = []
    val.forEach((doc) => {
      let id = doc.id
      let nombre = doc.data().nombre
      let precio = doc.data().precio
      let info = doc.data().info
      data.push(
        <div  className="col-6 my-3" key={doc.id}>             
          <div className='card-transparent text-center '>           
            <img src={doc.data().url}  alt="..." className='my-1 rounded-4 img-fluid' />            
            <br/>    
            <span className="small">{nombre}  </span>
            <br/>
            <span className="text-secondary small">{info}  </span>
            <span className="small fw-bold" id={`precio${id}`}>                      
              { (Number((doc.data().precio)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))} 
            </span>
            <div>
              <button className="h6 border-0 bg-transparent px-0 mx-0" onClick={() => sub(`${id}`, precio)}><GrFormSubtract /></button>
              <input  id={`cant${id}`} onChange={() => setPrecio(`${id}`, precio)} type="number" defaultValue={1} min="1" max="100" className='py-0 text-center rounded-3' style={{width:'50px'}}/>
              <button className="h6 border-0 bg-transparent px-0 mx-0" onClick={() => add(`${id}`, precio)}><GrFormAdd /> </button>   
              <br/>
              <small id={`btnadd${doc.id}`} className="btn btn-sm btn-dark bg-black rounded-4 small rounded-0 py-1" onClick={() => addPreCart(doc, idSup, type, precioSup)}>Seleccionar</small>  
            </div>
          </div>                            
        </div>    
      )
    })
    setExtra(data)
  }

  const readProduct = (doc) =>  {
    let data = []
    let id = doc.id
    let nombre = doc.data().nombre
    let precio = doc.data().precio
    data.push(
      <div id={`product${id}`} className="col-12 col-lg-3" key={doc.id}>         
        <div className="g-0 shadow rounded bg-white">
          <img src={doc.data().url}  alt="..." className='w-100 rounded-top'/> 
          <div className="row p-3">
            <div className="col-12 px-4">
              <h1 className="h5 text-dark">{nombre}</h1> 
            </div>
            <div className="col-12 px-4 mb-2">
              <span className='text-start small '>
                {doc.data().info}
                <br/>
                {(Number((doc.data().precio)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))} c/u
              </span>
            </div>
            <div className="col-6 px-4">
              <input id={`precioExtrasbebidas${id}`} type="hidden" value={0}/>
              <input id={`precioExtrasadicionales${id}`} type="hidden" value={0}/>
              <h5 className="h5 fw-bold" id={`precio${id}`}>                      
                {(Number((doc.data().precio)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))} 
              </h5>
            </div>
            <div className="col-6 text-end px-4">
              <button className="h6 border-0 bg-white px-0 mx-0" onClick={() => sub(`${id}`, precio)}><GrFormSubtract /></button>
              <input  id={`cant${id}`} onChange={() => setPrecio(`${id}`, precio)} type="number" defaultValue={1} min="1" max="100" className='py-0 text-center rounded-3' style={{width:'50px'}}/>
              <button className="h6 border-0 bg-white px-0 mx-0" onClick={() => add(`${id}`, precio)}><GrFormAdd /> </button>               
            </div>
            <div className="col-12 px-4">
              <div id={`subItemsadicionales${id}`}></div>
            </div>
            <div id={`btnaddadicionales${doc.id}`} className="col-12 px-4">
              <a  href={`#idExtras`} onClick={() => readExtra(value.adicionales, id, 'adicionales', precio)} className="btn btn-warning rounded-4 mb-2" data-bs-toggle="offcanvas">
                <PiBowlFoodDuotone className="mb-1 me-1"/> Combos
              </a> 
            </div>
            <div className="col-12 px-4">
              <div id={`subItemsbebidas${id}`}></div>
            </div>
            <div id={`btnaddbebidas${doc.id}`} className="col-12 pt-1 px-4">
              <a href={`#idExtras`} onClick={() => readExtra(value.bebidas, id , 'bebidas', precio)} className="btn btn-danger bg-danger rounded-4 mb-2 me-1" data-bs-toggle="offcanvas">
                <PiBeerBottleDuotone className="mb-1 me-1"/> Bebidas
              </a>
            </div>
            <div className="col-12  pt-3 text-center">
              <div className=' w-100'>                     
                <small id={`btnadd${doc.id}`} className="btn shadow btn-dark bg-black rounded-4 p-2 px-5" onClick={() => addCartAux(doc)}> Añadir al carrito</small>
              </div>
            </div>
          </div>
        </div>                          
      </div>        
    )
    setProduct(data)
    //modal
    document.getElementById("focusProduct").style.display = 'flex'
    document.body.style.overflow="hidden"


  }

  const read = async ([val]) =>  {
      let data = []
      val.forEach((doc) => {
        let id = doc.id
        let nombre = doc.data().nombre
        let precio = doc.data().precio
        if(verify(id)) {
          data.push(
            <div className="col-12 col-lg-6" key={doc.id} >         
              <div className="rounded shadow my-3 bg-white">
                <img src={doc.data().url}  alt="..." className='w-100 rounded-top' loading="lazy"/> 
                <div className="row p-3">
                  <div className="col-12 px-4">
                    <h1 className="h5 text-dark">{nombre}</h1> 
                  </div>
                  <div className="col-12 px-4 mb-2">
                    <span className='text-start small '>
                      {doc.data().info}
                    </span>
                  </div>
                  <div className="col-12 px-4">
                    <h5 className="h5 fw-bold" id={`precio${id}`}>                      
                      {(Number((doc.data().precio)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))} 
                    </h5>
                  </div>
                  <div className="col-12 text-center pt-4">
                    <div className=' w-100'>                     
                      <small id={`btnadd${doc.id}`} className="btn shadow btn-dark bg-black rounded-4 px-3" onClick={() => readProduct(doc)}> Añadir al carrito</small>
                    </div>
                  </div>
                </div>
              </div>                          
            </div>        
          )
        } else {
          data.push(
            <div className="col-12 col-lg-6" key={doc.id}>         
              <div className="rounded shadow my-3 bg-black">
                <img src={doc.data().url}  alt="..." className='w-100 rounded-top' loading="lazy"/> 
                <div className="row p-3">
                  <div className="col-12 px-4">
                    <h1 className="h5 text-light">{nombre}</h1> 
                  </div>
                  <div className="col-12 px-4 mb-2">
                    <span className='text-start small text-light'>
                      {doc.data().info}
                    </span>
                  </div>
                  <div className="col-6 px-4">
                    <h5 className="h5 fw-bold text-light" id={`precio${id}`}>                      
                      {(Number((doc.data().precio)).toLocaleString('es-CO', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}))} 
                    </h5>
                  </div>
                  <div className="col-6 text-end">
                    <div className=' w-100'>                     
                      <small id={`btnadd${doc.id}`} onClick={() => navigate("/cart")}><FiShoppingCart className="mb-0 ms-1 h4 text-danger"/></small>
                      <span className="position-absolute translate-middle badge rounded-pill bg-danger">
                        {getNumberItemsCart()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>                          
            </div>        
          )
        }
        
      })
      setDocs(data)
  }

  const verify = (id) =>  {
    let check = true
    querySnapshot.forEach((doc) => {
      let idCart = doc[0]
      if(id === idCart) check = false
    })
    return check
  }

  const dropPreCart = (id, idElement, type, idSup, precioSup) => {
    document.getElementById(`btnadd${type}${idSup}`).style.pointerEvents = 'auto'
    document.getElementById(`btnadd${type}${idSup}`).style.opacity = '1'
    subs.forEach((item, index) => {
      if(item[0] === id && item[6] === idSup) { 
        subs.splice(index,1)
      } 
    })

    document.getElementById((`precioExtras${type}${idSup}`)).value = "0"
    document.getElementById(`${idElement}`).remove()
    setPrecio(idSup, precioSup)
  }

  const addPreCart = (doc, idSup, type, precioSup) =>  {
    document.getElementById(`btnadd${type}${idSup}`).style.pointerEvents = 'none'
    document.getElementById(`btnadd${type}${idSup}`).style.opacity = '0.5'
    let cant = document.getElementById((`cant${doc.id}`)).value
    let id = doc.id
    let nombre = doc.data().nombre
    let info = doc.data().info
    let url = doc.data().url
    let precio = doc.data().precio
    let subtotal = precio *  cant
    let data = []
    data.push(
      <div id={`subItem${type}${idSup}`} className="col-12">                               
        <div className="row my-2 border-top p-1">
          <div className="col-2">
            <img src={url}  alt="..." className='img-fluid rounded-3' loading="lazy"/> 
          </div>
          <div className="col-7 ps-3 ">
            <h1 className="font fw-bold text-secondary">{nombre}</h1> 
            <span className='text-start font text-secondary '>{info}</span>
            <span className='text-start font text-secondary '> cant({cant})</span> 
          </div>
          <div className="col-2 text-center">
            <span className='text-start font text-secondary '>${subtotal}</span>        
          </div>
          <div className="col-1 text-center">
            <div onClick={() => dropPreCart(id, `subItem${type}${idSup}`, type, idSup, precioSup)}><AiOutlineClose /></div>        
          </div>
        </div>                          
      </div>            
    )
    subs.push([id, nombre, info, url, precio, cant, idSup])

    if(type === 'bebidas') {
      let precioExtrasBebidas = (document.getElementById((`precioExtrasbebidas${idSup}`)))
      precioExtrasBebidas.value = Number(subtotal)

    } 
    if(type === 'adicionales') {
      let precioExtrasAdicionales = (document.getElementById((`precioExtrasadicionales${idSup}`)))
      precioExtrasAdicionales.value = Number(subtotal)
    } 
    
    let element  = ReactDOM.createRoot(document.getElementById(`subItems${type}${idSup}`))
    element.render( data )

    setPrecio(idSup, precioSup)
    document.getElementById("closeModalExtras").click()
  }


  const closeModalProduct = () =>  {
    document.getElementById("focusProduct").style.display = 'none'
    document.body.style.overflow="auto"
  }

  return ( 
    <>
      <div className="bg" />
        <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={10}
        containerClassName=""
        containerStyle={{
        }}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
            font: 'menu',
            fontSize: '13.5px',
            borderRadius: '0'
          },
      
          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
        />

        <div className="container offcanvas offcanvas-end p-3 bg-white" tabIndex="-1" id={`idExtras`} data-bs-backdrop="false"> 
          <div className="offcanvas-header">
            <h1> </h1>
            <button id="closeModalExtras" type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body row"> 
            {extra}
          </div>
        </div>

        <div className="modalProduct" id="focusProduct">
          <div onClick={() => closeModalProduct()} className='previous-right h2'><GrFormClose/></div> 
          <div className="container-fluid px-2">
            <div className="row d-flex justify-content-center">
              {product}
            </div>
          </div>
        </div>

        <nav className="fixed-top bg-blur p-2" >
          <Link to='/' className='previous h2'><GrFormPrevious/></Link>
          <div className="text-center">
            <h1 id="page" className='h3 text-danger'>MENÚ</h1>
          </div>
          <div className="cart-container">
            <Link to='/cart' className='px-lg-2 cart m-0 p-0'><FiShoppingCart/></Link>
            <span className="position-absolute translate-middle badge rounded-pill bg-danger">
              {getNumberItemsCart()}
            </span>
          </div>
        </nav>     
        <div className="container  pb-5 animate__animated animate__fadeIn">
          <div className="row my-lg-5 pt-5">
            <div className="col-12">
              <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabIndex="0">             
                <div className='row mt-3 mt-lg-5'>
                  <div className='col-12 col-lg-4'>
                    <ul className="list-group mt-3 list-group-flush h4 d-none d-lg-block menu-desktop">                         
                        <li className={`list-group-item py-4 user-select-none fw-bold ${option === 'sandwiches' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/sandwiches")}>Sandwiches</li>              
                        <li className={`list-group-item py-4 user-select-none fw-bold ${option === 'hamburguesas' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/hamburguesas")}>Hamburguesas</li>              
                        <li className={`list-group-item py-4 user-select-none fw-bold ${option === 'perros' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/perros")}>Perros calientes</li>              
                        {/*<li className={`list-group-item py-4 user-select-none fw-bold ${option === 'combos' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/combos")}>Combos</li>*/}
                        <li className={`list-group-item py-4 user-select-none fw-bold ${option === 'otros' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/otros")}>Otros</li>              
                    </ul>
                    <div className=" h7 d-block d-lg-none menu-movil bg-blur text-center py-2">                     
                      <div className={`list-group-item mx-1 d-inline-block ${option === 'sandwiches' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/sandwiches")}>Sandwiches</div>              
                      <div className={`list-group-item  mx-1 d-inline-block ${option === 'hamburguesas' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/hamburguesas")}>Hamburguesas</div>              
                      <div className={`list-group-item  mx-1 d-inline-block ${option === 'perros' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/perros")}>Perros calientes</div>              
                      {/*<div className={`list-group-item  mx-1 d-inline-block ${option === 'combos' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/combos")}>Combos</div>*/}
                      <div className={`list-group-item  mx-1 d-inline-block ${option === 'otros' ? 'active' : ''}`} onClick={() => navigateScroll("/menu/otros")}>Otros</div>              
                    </div>                   
                  </div>
                  <div id='containerDocs' className='col-12 col-lg-8 px-lg-3 mt-4 mt-lg-0'>
                    <div className="row">                
                    {docs}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
    </>
  )

}

export default Menu